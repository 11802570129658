import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/linkbutton"
import { Helmet } from "react-helmet"
import "../stylesheets/recruit.scss"

const RecruitPage = () => (
  <Layout>
    <SEO
      title="採用情報"
      description="道新インタラクティブ株式会社の採用情報です。採用情報の詳細は、ジョブアンテナ北海道にて閲覧可能です。"
    />
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <div className="recruitPage-MainVisual">
      <div className="recruitPage-MainVisual-headingWrapper">
        <h1 className="recruitPage-MainVisual-heading">採用情報</h1>
        <p className="recruitPage-MainVisual-subHeading">Recruit</p>
      </div>
    </div>
    <section className="recruitPage-OfferSection">
      <div className="recruitPage-OfferSection-inner">
        <p className="recruitPage-OfferSection-text">
          私たちは常に挑戦を続け、共に成長できる方を求めています。
          <br />
          「北海道の未来を創りたい」
          <br />
          「地方から求人マーケットを塗り替えたい」
          <br />
          「地方創生に関わって日本全国を元気にしたい」
          <br />
          <br />
          そういった熱い想いを持った方からのエントリーをお待ちしております。
        </p>
        <div className="recruitPage-OfferSection-itemWrapper">
          <h2 className="recruitPage-OfferSection-heading">募集職種一覧</h2>
          <div className="recruitPage-OfferSection-offerList">
            <div className="recruitPage-OfferSection-offerItem">
              <h3 className="recruitPage-OfferSection-offerHeading">
                求人広告ライター・制作ディレクター
              </h3>
              <p className="recruitPage-OfferSection-offerText">
                企業の魅力を発信する、
                <br />
                自社サービスのライティング/コンテンツ編集業務
              </p>
              <div className="recruitPage-OfferSection-buttonWrapper">
                <Button
                  to="https://www.jobantenna.jp/partners/contact/"
                  target="_blank"
                  className="Button--secondary"
                >
                  ジョブアンテナで詳細を見る
                </Button>
              </div>
            </div>
            <div className="recruitPage-OfferSection-offerItem">
              <h3 className="recruitPage-OfferSection-offerHeading">
                新規法人営業・企画営業職
              </h3>
              <p className="recruitPage-OfferSection-offerText">
                北海道の求人市場の未来を変える、
                <br />
                求人サービスの新規法人営業
              </p>
              <div className="recruitPage-OfferSection-buttonWrapper">
                <Button
                  to="https://www.jobantenna.jp/partners/contact/"
                  target="_blank"
                  className="Button--secondary"
                >
                  ジョブアンテナで詳細を見る
                </Button>
              </div>
            </div>
            <div className="recruitPage-OfferSection-offerItem">
              <h3 className="recruitPage-OfferSection-offerHeading">
                人材紹介部門責任者
              </h3>
              <p className="recruitPage-OfferSection-offerText">
                企業の人事組織のパートナーとして、
                <br />
                課題解決を行う人材紹介部門の責任者
              </p>
              <div className="recruitPage-OfferSection-buttonWrapper">
                <Button
                  to="https://www.jobantenna.jp/partners/contact/"
                  target="_blank"
                  className="Button--secondary"
                >
                  ジョブアンテナで詳細を見る
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default RecruitPage
