import * as React from "react"
import PropTypes, { string } from "prop-types"
import { Link } from "gatsby"
import "../stylesheets/linkbutton.scss"

const Button = ({ children, to, className, target }) => {
  if (target === "_blank") {
    return (
      <a href={to} target="_blank" rel="noreferrer" className={className}>
        {children}
      </a>
    )
  } else {
    return (
      <Link to={to} className={className}>
        {children}
      </Link>
    )
  }
}
Button.propTypes = {
  children: PropTypes.node.isRequired,
  to: string,
  className: string,
  target: string,
}

export default Button
